import React from "react";
import { useState } from "react";

import { donate } from "./../api/call";

import Pm from "./../assets/Group 367@2x.png";
import { AiOutlineClose } from "react-icons/ai";

export const DonateModal = ({ visible, onClose }) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (value > 0) {
      const data = { donation: value };

      const response = await donate(data);
      console.log(response.redirectUrl, "response");
      window.location.href = response.redirectUrl;
    } else {
      alert("Atleast 1 php");
    }
  };

  if (!visible) return false;

  return (
    <div className="fixed w-full h-[100vh] top-0 left-0 inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
      <div className="md:w-[30%] w-[70%] bg-white rounded-xl flex">
        <div className="m-5">
          <div className="w-full items-center justify-end flex">
            <AiOutlineClose
              className="text-[#d6d6d6] cursor-pointer"
              onClick={onClose}
            />
          </div>
          <p className="w-full text-fontColor md:text-[1.2vw] text-[3vw] font-ceraMid">
            DONATE ONLINE WITH MAYA
          </p>
          <p className="mb-2 w-full text-fontHover md:text-[0.9vw] text-[2.7vw] font-ceraMid">
            Log-in to your account to complete the Donation
          </p>
          <p className="w-full text-fontHover md:text-[0.7vw] text-[1.7vw] font-ceraMid">
            Php.
          </p>
          <input
            type="number"
            name="contactName"
            value={value}
            placeholder="Enter amount"
            className="mb-2 w-full text-font Hover md:text-[1.17vw] text-[4.7vw] font-ceraMid"
            onChange={handleChange}
            pattern="\d+"
            required
          />
          <button
            onClick={handleSubmit}
            type="submit"
            className="mt-2 md:w-[50%] w-[60%] h-auto cursor-pointer"
          >
            <img src={Pm} alt="Submit button" />
          </button>
        </div>
      </div>
    </div>
  );
};
