import React from "react";
import Bg from "./../assets/bg-5.png";
import Pm from "./../assets/Group 367@2x.png";
import Bg2 from "./../assets/banner@2x.png";
import { DonateModal } from "./DonateModal";
import { useState } from "react";

export const Contribute = (props) => {
  const [showDonateModal, setShowDonateModal] = useState(true);

  const onCloseDonate = () => setShowDonateModal(false);

  return (
    <div
      id="contribute"
      className="relative justify-center h-[calc(100vh_-_100px)] z-10"
    >
      <DonateModal visible={showDonateModal} onClose={onCloseDonate} />

      <img
        src={Bg2}
        alt="/"
        className="absolute object-cover bg-no-repeat bg-center w-full h-[100%] md:h-[105%] lg:h-[110%] opacity-80"
      />
      <img
        src={Bg}
        alt="/"
        className="relative object-cover md:object-fill bg-no-repeat bg-center w-full h-full"
      />
      <div className="absolute w-full h-full top-2 left-0 z-20 mt-0 md:mt-[-30px]">
        <div className="absolute w-[80vw] h-[60vh] top-[12vh] left-[7.5vw]">
          <p className="text-white font-ceraBold md:text-[3vw] text-[4vw]">
            Contribute
          </p>
          <p className="text-white font-ceraMid md:text-[.8vw] sm:text-[1vw] text-[1.7vw] md:w-[47%] w-[80%]">
            Donate and help us make this dream become a reality to them where
            people in far-flung areas don’t need to struggle just to get a clean
            water.
          </p>
          <div className=" w-full max-h-[80%] relative grid grid-rows-1 grid-cols-2 pt-3 gap-20">
            <div className="col-span-1 row-span-1">
              <div className="w-full h-full m-auto">
                <p className="font-ceraBold text-white md:text-[2vw] text-[3vw] text-left">
                  Our Current Project
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left pt-3">
                  Since March 2022, we have been helping a community of the Aeta
                  Tribe situated in Burgos, Zambales.
                </p>
                <div className="grid grid-rows-3 grid-cols-2 w-full h-auto">
                  <div className="col-span-1 row-span-3 gap-1">
                    <p className="font-ceraBold text-white md:text-[1.5vw] sm:text-[2vw] text-[2.5vw] text-left align-text-bottom">
                      {props.data.fam} Families
                    </p>
                  </div>
                  <div className="col-span-1 row-span-2 gap-1">
                    <p className="font-ceraBold text-white md:text-[1.5vw] sm:text-[2vw] text-[2.5vw] text-left align-text-bottom">
                      {props.data.individual} Individuals
                    </p>
                  </div>
                  <div className="col-span-1 row-span-1 gap-1">
                    <p className="font-ceraItalic text-white md:text-[.8vw] sm:text-[1vw] text-[1.5vw] text-right">
                      elderly, adults & Children
                    </p>
                  </div>
                </div>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left pt-3">
                  These people have to travel 3 hours by foot just to get to
                  their water source to do their laundry, fulfill their basic
                  hygiene routines and collect water for them to haul it back to
                  their community to provide for their families.
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left pt-3">
                  It is a very tedious task and upon meeting the community we
                  really felt the need to support these people, As of this month
                  of May 2022, the construction of the water pump is at 80% and
                  we are looking at the 2nd /3rd week of May to have it fully
                  operational. We are all excited for the water pump
                  installation as we can begin with our plans of sustainable
                  community building.
                </p>
              </div>
            </div>
            <div className="col-span-1 row-span-1">
              <div className="w-[90%] h-full m-auto">
                <p className="font-ceraBold text-white md:text-[2vw] text-[3vw] text-left">
                  Bank Transfer
                </p>
                <img
                  src={props.data.bank_img}
                  alt="/"
                  className="md:h-[30px] h-[30px] p-2"
                />
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left">
                  {props.data.branch}
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left ml-[10%]">
                  Account #: {props.data.bank_acct}
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left ml-[10%]">
                  Account name: {props.data.acct_name}
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left ml-[10%]">
                  Swift code: {props.data.swift_cd}
                </p>
                <p className="font-ceraBold text-white md:text-[2vw] text-[3vw] text-left pt-3">
                  Donate Online with Paymaya
                </p>
                <p className="font-ceraMid text-white md:text-[.8vw] sm:text-[1vw] text-[1.7vw] text-left pt-1">
                  Log-in to your account to complete the Donation
                </p>
                <img
                  src={Pm}
                  onClick={setShowDonateModal}
                  alt="/"
                  className="md:w-[50%] w-[80%] h-auto p-3 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
